import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Modal,
  Button,
  Input,
  message,
  Alert,
  DatePicker,
  Checkbox,
  Tag,
} from "antd";
import { Formik, Form, Field } from "formik";
import {
  CloseOutlined,
  LinkOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Select, Space } from "antd";
import CreateNotificationGroup from "./CreateGroup";
import * as Yup from "yup";
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent";
import {
  updateNotifications,
  notificationGroupsByOrganizationID,
  listUserOfOrganization,
  listOrganizationUserRoles,
} from "utils/Actions";
import { useSelector } from "react-redux";
import {
  getCurrentIdToken,
  getOrganizationId,
  getCurrentUser,
} from "store/slices/loginSlice";
import { CommonError } from "utils/Helper/CommonError";
import {
  createNotification,
  listItemsTypesSearch,
  listProcessSearch,
} from "utils/RESTApi";

const CreateNotification = ({
  open,
  onCancel,
  loading: initialLoading,
  notificationConfigDataEdit,
  notificationData,
  handleCreateGroup,
  fetchData,
}) => {
  const formikRef = useRef();
  const { TextArea } = Input;
  const [createNotificationGroupVisible, setCreateNotificationGroupVisible] =
    useState(false);
  const [externalContactWarning, setExternalContactWarning] = useState(null);
  const [contactOptions, setContactOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [notificationGroups, setNotificationGroups] = useState([]);
  const userOrganizationId = useSelector(getOrganizationId);
  const currentIdToken = useSelector(getCurrentIdToken);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const triggerOptions = ["One Time", "Interval"];
  const repeatOptions = ["Weekly", "Monthly", "Daily", "Yearly"];
  const intervalStartOptions = [
    "Date & Time",
    "Item Creation",
    "Process Creation",
  ];
  const [processData, setProcessData] = useState([]);
  const [itemTypeOption, setItemTypeOption] = useState([]);

  // fetch process
  const fetchProcessData = async (search) => {
    try {
      const params = {
        organizationId: userOrganizationId,
        keyword: search,
      };

      const response = await listProcessSearch(params);
      const processOption = response?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));

      setProcessData(processOption);
    } catch (error) {
      CommonError(error);
    }
  };

  // fetch item types
  const fetchItemTypes = async (search) => {
    try {
      const params = {
        organizationId: userOrganizationId,
        keyword: search,
      };

      const response = await listItemsTypesSearch(params);
      const itemTypeOption = response?.data?.data?.map((item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.number})`,
      }));

      setItemTypeOption(itemTypeOption);
    } catch (error) {
      CommonError(error);
    }
  };

  // Fetch organization users
  const fetchOrganizationUsers = useCallback(async () => {
    try {
      setLoading(true);
      const filter = {
        organizationID: { eq: userOrganizationId },
      };
      const response = await listOrganizationUserRoles(filter, currentIdToken);
      const user = response?.items?.filter(
        (item) =>
          item?.User?.isEnabled &&
          !item?.User?.isDeleted &&
          item?.User?.id !== currentUser?.sub
      );
      const transformedOptions = user.map((item) => ({
        value: item.User.id,
        label: `${item.User.firstName} ${item.User.lastName}`,
        email: item.User.email,
      }));
      setContactOptions(transformedOptions);
    } catch (err) {
      CommonError(err);
    } finally {
      setLoading(false);
    }
  }, [userOrganizationId, currentIdToken]);
  // Fetch Notification Groups
  const fetchNotificationGroups = useCallback(async () => {
    const filter = {
      organizationID: { eq: userOrganizationId },
    };
    try {
      const response = await notificationGroupsByOrganizationID(
        userOrganizationId,
        currentIdToken,
        filter
      );
      const groups = response?.items || [];
      setNotificationGroups(groups);
      // Prepare group options from fetched groups
      const preparedGroupOptions = groups.map((group) => ({
        value: group.id,
        label: group.name,
      }));
      setGroupOptions(preparedGroupOptions);
    } catch (error) {
      CommonError(error);
    }
  }, [userOrganizationId, currentIdToken]);
  // Fetch users and groups on component mount or when organization changes
  useEffect(() => {
    if (open) {
      fetchOrganizationUsers();
      fetchNotificationGroups();
      fetchProcessData();
      fetchItemTypes();
    }
  }, [open, fetchOrganizationUsers, fetchNotificationGroups, fetchData]);
  // Validation Schema
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Notification name is required")
      .min(3, "Notification name must be at least 3 characters")
      .max(50, "Notification name must not exceed 50 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description must be at least 10 characters"),
    memberIds: Yup.array().when(["groupIds"], {
      is: (groupIds) => groupIds === null || groupIds.length === 0,
      then: () =>
        Yup.array()
          .min(1, "Select at least one contact or any one Group")
          .required("Select at least one contact or any one Group"),
      otherwise: () => Yup.array().nullable(),
    }),
    triggerType: Yup.string().required("Trigger type is required"),
    oneTimeDate: Yup.date().when(["triggerType"], {
      is: (value) => value === "One Time",
      then: () => Yup.date().required("Date and time are required"),
      otherwise: () => Yup.date().nullable(),
    }),
    intervalStart: Yup.string().when("triggerType", {
      is: (value) => value === "Interval",
      then: () => Yup.string().required("Interval start is required"),
      otherwise: () => Yup.date().nullable(),
    }),
    repeatType: Yup.string().when(["triggerType", "intervalStart", "repeat"], {
      is: (triggerType, intervalStart, repeat) =>
        triggerType === "Interval" && intervalStart === "Date & Time" && repeat,
      then: () => Yup.string().required("Repeat type is required"),
      otherwise: () => Yup.string().nullable(),
    }),
    itemTypeId: Yup.string().when(["triggerType", "intervalStart"], {
      is: (triggerType, intervalStart) =>
        triggerType === "Interval" && intervalStart === "Item Creation",
      then: () => Yup.string().required("Item type is required"),
      otherwise: () => Yup.string().nullable(),
    }),
    processId: Yup.string().when(["triggerType", "intervalStart"], {
      is: (triggerType, intervalStart) =>
        triggerType === "Interval" && intervalStart === "Process Creation",
      then: () => Yup.string().required("Process is required"),
      otherwise: () => Yup.string().nullable(),
    }),
    // groupIds: Yup.array().nullable(),
  });
  // Initial Values
  const [initialValues, setInitialValues] = useState({
    id: null,
    name: "",
    description: "",
    memberIds: [],
    groupIds: [],
    triggerType: null,
    oneTimeDate: null,
    intervalStart: "",
    repeatType: null,
    repeat: false,
    itemTypeId: null,
    processId: null,
  });
  // Prepare contact and initial values
  useEffect(() => {
    if (notificationConfigDataEdit && notificationData) {
      // Set initial values for edit scenario
      setInitialValues({
        id: notificationData.id,
        name: notificationData.name || "",
        description: notificationData.description || "",
        memberIds: notificationData.memberIds || [],
        groupIds: notificationData.groupIds || [],
        triggerType: notificationData.triggerType || "",
        oneTimeDate: notificationData.oneTimeDate || null,
        intervalStart: notificationData.intervalStart || "",
        repeatType: notificationData.repeatType || null,
        repeat: notificationData.repeat || false,
        itemTypeId: notificationData.itemTypeId || null,
        processId: notificationData.processId || null,
        emailIds: notificationData.emailIds || [],
      });
    } else {
      // Reset to default initial values
      setInitialValues({
        id: null,
        name: "",
        description: "",
        memberIds: [],
        groupIds: [],
        triggerType: null,
        oneTimeDate: null,
        intervalStart: "",
        repeatType: null,
        repeat: false,
        itemTypeId: null,
        processId: null,
        emailIds: [],
      });
    }
  }, [notificationConfigDataEdit, notificationData]);
  // Handle form submission
  // In the handleSubmit function, modify the update logic
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setLoading(true);
    try {
      // Validate all fields
      await validationSchema.validate(values, { abortEarly: false });

      const internalContacts = [];
      const externalEmails = [];

      values?.memberIds?.forEach((contact) => {
        // Check if contact exists in contactOptions (internal)
        const isInternal = contactOptions.some((opt) => opt.value === contact);

        if (isInternal) {
          internalContacts.push(contact);
        } else {
          // Validate email format
          const isValidEmail =
            typeof contact === "string" &&
            contact.includes("@") &&
            contact.includes(".");
          if (isValidEmail) {
            externalEmails.push(contact);
          }
        }
      });

      // Prepare mutation input exactly matching the GraphQL schema
      const data = {
        id: values.id,
        organizationID: userOrganizationId,
        name: values.name,
        description: values.description,
        triggerType: values.triggerType,
        repeat: values.repeat,
        repeatType: values.repeatType,
        intervalStart: values.intervalStart,
        itemTypeId: values.itemTypeId,
        oneTimeDate: values.oneTimeDate,
        processId: values.processId,
      };
      // Conditional logic for memberIds
      if (notificationConfigDataEdit) {
        // For update, just send memberIds array
        data.memberIds = internalContacts;
      } else {
        // For create, transform memberIds
        data.memberIds = internalContacts.map((memberId) => {
          const contact = contactOptions.find(
            (option) => option.value === memberId
          );
          return {
            id: contact.value,
            email: contact.email,
          };
        });
        // Additional fields for create scenario
        data.groupIds = values.groupIds || [];
        data.emailIds = externalEmails || [];
        data.createdBy = currentUser?.sub;
      }

      // cosole.log("input values", data);
      // Call create or update mutation
      const response = notificationConfigDataEdit
        ? await updateNotifications(data, currentIdToken)
        : await createNotification(data);

      // Show success message
      message.success(
        notificationConfigDataEdit
          ? "Notification Updated Successfully"
          : "Notification Created Successfully"
      );
      // Reset form and close modal
      onCancel();
      setLoading(false);
      if (formikRef.current) {
        formikRef.current.resetForm();
      }
    } catch (err) {
      CommonError(err);
      setLoading(false);
    } finally {
      setSubmitting(false);
      setExternalContactWarning(null);
    }
  };
  // Handle contact change with filtering for internal and external contacts
  const handleContactChange = (value, setFieldValue) => {
    // Ensure value is an array and filter out any undefined/null values
    const validValues = (value || []).filter((v) => v != null);

    // Split contacts into internal and external arrays
    const internalContacts = [];
    const externalEmails = [];

    validValues.forEach((contact) => {
      // Check if contact exists in contactOptions (internal)
      const isInternal = contactOptions.some((opt) => opt.value === contact);

      if (isInternal) {
        internalContacts.push(contact);
      } else {
        // Validate email format
        const isValidEmail =
          typeof contact === "string" &&
          contact.includes("@") &&
          contact.includes(".");
        if (isValidEmail) {
          externalEmails.push(contact);
        }
      }
    });

    // Set warning for external contacts
    if (externalEmails.length > 0) {
      const warningMessage =
        externalEmails.length === 1
          ? `Are you trying to add an external user: ${externalEmails[0]}?`
          : `Are you trying to add ${externalEmails.length} external users?`;
      setExternalContactWarning(warningMessage);
    } else {
      setExternalContactWarning(null);
    }

    // Update form values
    setFieldValue("memberIds", value);
    // setFieldValue("emailIds", externalEmails);
  };
  // Handle modal cancel
  const handleCancel = () => {
    // Reset the form
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    // Clear external contact warning
    setExternalContactWarning(null);
    // Call the original onCancel prop
    onCancel();
  };

  // Handle closing of Create Group modal
  const handleCreateGroupCancel = () => {
    setCreateNotificationGroupVisible(false);
    fetchNotificationGroups();
  };

  const trigger = triggerOptions.map((option) => ({
    value: option,
    label: option,
  }));
  const repeat = repeatOptions.map((option) => ({
    value: option,
    label: option,
  }));

  const intervalStart = intervalStartOptions.map((option) => ({
    value: option,
    label: option,
  }));

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  // Custom tag render function to differentiate internal and external contacts
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;

    if (!value) {
      return null;
    }

    // Check if the value is an internal contact (exists in contactOptions)
    const isInternalContact = contactOptions.some(
      (opt) => opt?.value === value
    );

    // If it's not internal and contains @, it's an external email
    const isExternalEmail =
      !isInternalContact && typeof value === "string" && value.includes("@");

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={
          isInternalContact ? "cyan" : isExternalEmail ? "gold" : "default"
        }
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {isInternalContact ? label : value}
      </Tag>
    );
  };

  return (
    <Modal
      title={
        notificationConfigDataEdit ? "Edit Notification" : "Create Notification"
      }
      className="modal"
      open={open}
      width={350}
      centered
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            if (formikRef.current) {
              formikRef.current.resetForm();
            }
            // Clear external contact warning
            setExternalContactWarning(null);
          }}
        >
          Reset
        </Button>,
        <Button
          key="submit"
          type="primary"
          form="CreateNotification"
          htmlType="submit"
          loading={loading}
        >
          {notificationConfigDataEdit ? "Update" : "Send"}
        </Button>,
      ]}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        enableReinitialize={true}
        validateOnMount={false}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({ errors, touched, setFieldValue, values }) => {
          console.log("Values", values);
          return (
            <Form id="CreateNotification" className="delete-modal">
              <div className="mb-15">
                <label htmlFor="name">Notification Name</label>
                <Field
                  type="text"
                  name="name"
                  as={Input}
                  placeholder="Enter Notification Name"
                />
                {touched.name && errors.name && (
                  <ErrorMessageComponent error={errors.name} />
                )}
              </div>

              <div className="mb-10">
                <label htmlFor="description">Description</label>
                <Field
                  as={TextArea}
                  placeholder="Enter Description"
                  name="description"
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                />
                {touched.description && errors.description && (
                  <ErrorMessageComponent error={errors.description} />
                )}
              </div>

              <div className="mb-10">
                <label htmlFor="memberIds">Select Contacts</label>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    name="memberIds"
                    style={{ width: "100%" }}
                    mode="tags"
                    className="custom_multiselection"
                    allowClear
                    placeholder="Please select Contacts"
                    onChange={(value) =>
                      handleContactChange(value, setFieldValue)
                    }
                    options={contactOptions}
                    value={[
                      ...(values.memberIds || []),
                      ...(values.emailIds || []),
                    ]} // Combine both internal and external contacts
                    loading={loading}
                    tagRender={tagRender}
                    optionFilterProp="label"
                    filterOption={(input, option) => {
                      const labelMatch = option?.label
                        ?.toLowerCase()
                        .includes(input.toLowerCase());
                      const emailMatch = option?.email
                        ?.toLowerCase()
                        .includes(input.toLowerCase());
                      return labelMatch || emailMatch || false;
                    }}
                  />
                </Space>
                {touched.memberIds && errors.memberIds && (
                  <ErrorMessageComponent error={errors.memberIds} />
                )}
                {externalContactWarning && (
                  <Alert
                    message={externalContactWarning}
                    type="warning"
                    showIcon
                    style={{ marginTop: 10 }}
                  />
                )}
              </div>

              <div className="mb-10">
                <label htmlFor="groupIds">Group List (optional)</label>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    name="groupIds"
                    style={{ width: "100%" }}
                    mode="multiple"
                    className="custom_multiselection"
                    allowClear
                    placeholder="Please select groups"
                    onChange={(value) => setFieldValue("groupIds", value)}
                    options={groupOptions}
                    value={values.groupIds}
                    loading={loading}
                  />
                </Space>
                {touched.groupIds && errors.groupIds && (
                  <ErrorMessageComponent error={errors.groupIds} />
                )}
              </div>

              <div>
                <Button
                  type="link"
                  className="text-primary fs-14 p-0"
                  onClick={() => handleCreateGroup(true)}
                >
                  <PlusCircleOutlined /> Create Group
                </Button>
              </div>

              <div className="mb-10">
                <label htmlFor="triggerType">Trigger Type</label>
                <Select
                  name="triggerType"
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Select Type"
                  onChange={(value) => {
                    setFieldValue("triggerType", value);
                    setFieldValue("intervalStart", null);
                    setFieldValue("oneTimeDate", null);
                    setFieldValue("repeat", false);
                    setFieldValue("repeatType", null);
                  }}
                  options={trigger}
                  value={values?.triggerType}
                />
                <ErrorMessageComponent error={errors?.triggerType} />
              </div>

              {/* Interval Trigger */}
              {values?.triggerType === "Interval" && (
                <div className="mb-10">
                  <label>Start</label>
                  <Select
                    showSearch
                    placeholder="Select Interval"
                    onChange={(value) => {
                      setFieldValue("intervalStart", value);
                      setFieldValue("repeat", false);
                      setFieldValue("itemTypeId", null);
                      setFieldValue("processId", null);
                      setFieldValue("repeatType", null);
                      setFieldValue("oneTimeDate", null);
                    }}
                    options={intervalStart}
                    value={values?.intervalStart}
                  />
                  <ErrorMessageComponent error={errors?.intervalStart} />
                </div>
              )}
              {values?.triggerType === "Interval" &&
                values?.intervalStart === "Date & Time" && (
                  <div>
                    <Checkbox
                      checked={values?.repeat}
                      onChange={(e) => {
                        setFieldValue("repeat", e.target.checked);
                        setFieldValue("repeatType", null);
                      }}
                      className="mb-10"
                    >
                      Repeat
                    </Checkbox>
                  </div>
                )}
              {(values?.triggerType === "One Time" ||
                (values?.triggerType === "Interval" &&
                  values?.intervalStart === "Date & Time")) && (
                  <div className="mb-10">
                    <label>Select Date & Time</label>
                    <DatePicker
                      showTime={{
                        format: "HH:mm",
                      }}
                      format="DD-MM-YYYY hh:mm"
                      use12Hours
                      hourStep={1}
                      minuteStep={15}
                      className="w-100 datepicker"
                      variant="filled"
                      placeholder="Select Date & Time"
                      onChange={(date) => setFieldValue("oneTimeDate", date)}
                      value={values?.oneTimeDate}
                    />
                    <ErrorMessageComponent error={errors?.oneTimeDate} />
                  </div>
                )}
              {values?.triggerType === "Interval" && values?.repeat && (
                <div className="mb-10">
                  <Select
                    showSearch
                    placeholder="Select Repeat Type"
                    onChange={(value) => setFieldValue("repeatType", value)}
                    options={repeat}
                    value={values?.repeatType}
                  />
                  <ErrorMessageComponent error={errors?.repeatType} />
                </div>
              )}
              {values?.triggerType === "Interval" &&
                values?.intervalStart === "Item Creation" && (
                  <div className="mb-10">
                    <Select
                      showSearch
                      placeholder="Select item creation"
                      onChange={(value) => setFieldValue("itemTypeId", value)}
                      options={itemTypeOption}
                      value={values?.itemTypeId}
                    />
                    <ErrorMessageComponent error={errors?.itemTypeId} />
                  </div>
                )}
              {values?.triggerType === "Interval" &&
                values?.intervalStart === "Process Creation" && (
                  <div className="mb-10">
                    <Select
                      showSearch
                      placeholder="Select process creation"
                      onChange={(value) => setFieldValue("processId", value)}
                      options={processData}
                      value={values?.processId}
                    />
                    <ErrorMessageComponent error={errors?.processId} />
                  </div>
                )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
export default CreateNotification;
