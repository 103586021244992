import React, { Suspense, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Mainlayout from "../MainLayout/Mainlayout";
import { useSelector } from "react-redux";
import {
  getOrganizationId,
  getCurrentOrganizationIntegrations,
  getCurrentIdToken,
} from "store/slices/loginSlice";
import { SubscribedRoutes } from "utils/Constants";
import { withSubscribedRoute } from "utils/Hoc/withSubscribedRoute";
import { getOrganization } from "utils/Actions";
import { CommonError } from "utils/Helper/CommonError";
import { Auth } from "aws-amplify";

export default function MainLayoutRoute({ children }) {
  const location = useLocation();
  const token = useSelector(getOrganizationId);
  const subscriptions = useSelector(getCurrentOrganizationIntegrations);
  const currentIdToken = useSelector(getCurrentIdToken);

  const [enableOrganization, setEnableOrganization] = useState(true);
  const errorContent = {
    message:
      "Your site has been disabled, please contact the Idencia Support team at support@idencia.com",
  };

  const getOrganizationData = async () => {
    try {
      const response = await getOrganization(token, currentIdToken);
      setEnableOrganization(response?.isEnabled);
    } catch (error) {
      CommonError(error);
    }
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut({ global: false });
      localStorage.clear();
      window.location.reload();
    } catch (error) {
      CommonError(error);
    }
  };

  if (!token) {
    return <Navigate replace state={{ from: location }} to="/login" />;
  } else {
    getOrganizationData();

    if (!enableOrganization) {
      CommonError(errorContent);
      handleSignOut();
    }
  }
  if (
    SubscribedRoutes.includes(location.pathname) &&
    !withSubscribedRoute(subscriptions, location.pathname)
  ) {
    return <Navigate replace to="/" />;
  }

  return (
    <>
      {/* {Rendering the childrens of all Public layout routes using the children(via props) or react-router-dom outlet} */}
      <Mainlayout>
        <Suspense>{children || <Outlet />}</Suspense>
      </Mainlayout>
    </>
  );
}
